import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Overlay
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        // this.time = this.experience.time

        
 
        
        this.setOverlay()
        
        
    }
    
    setOverlay()
    {   
        const overlayColor = new THREE.Color( 0xffcfde )
        this.overlayMaterial = new THREE.ShaderMaterial({
            transparent: true,
            depthWrite:false,
            uniforms: {
                uAlpha: { value: 1 },
                uColor: { value: overlayColor }
            },
            vertexShader: `
                void main()
                {
                    gl_Position = vec4(position, 1.0);
                }
            `,
            fragmentShader: `
                uniform float uAlpha;
                uniform vec3 uColor;
        
                
                void main()
                {
                    gl_FragColor = vec4(uColor, uAlpha);
                }
            `
        })
        this.overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1)
        this.overlay = new THREE.Mesh(this.overlayGeometry,this.overlayMaterial)
        this.scene.add(this.overlay)
    }

   
}