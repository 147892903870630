export default [
    {
        name: 'environmentMapTexture',
        type: 'cubeTexture',
        path:
        [
            'img/px.jpg',
            'img/nx.jpg',
            'img/py.jpg',
            'img/ny.jpg',
            'img/pz.jpg',
            'img/nz.jpg'
        ]
    },
    {
        name: 'floorTexture',
        type: 'texture',
        path: 'img/floorPink.jpg'
    },
    {
        name: 'boxModel',
        type: 'gltfModel',
        path: 'models/reelBox2.gltf'
    },
    {
        name: 'onlyV',
        type: 'gltfModel',
        path: 'models/onlyV.gltf'
    },
    {
    name: 'toonImg',
    type: 'texture',
    path: 'img/toon.jpg'
    }, 
    {
        name: 'floorModel',
        type: 'gltfModel',
        path: 'models/floor.gltf'
    }, 
    {
        name: 'boxImg',
        type: 'texture',
        path: 'img/reelBox.png'
    },
    {
        name: 'cross1Img',
        type: 'texture',
        path: 'img/cross1.jpg'
    },
    {
        name: 'cross2Img',
        type: 'texture',
        path: 'img/cross2.png'
    },
    {
        name: 'cube1Img',
        type: 'texture',
        path: 'img/cube1.jpg'
    },
    {
        name: 'tubeImg',
        type: 'texture',
        path: 'img/tube.jpg'
    },
    {
        name: 'easeImg',
        type: 'texture',
        path: 'img/ease.jpg'
    },
    {
        name: 'contImg',
        type: 'texture',
        path: 'img/cont.png'
    },
    {
        name: 'cylImg',
        type: 'texture',
        path: 'img/cylinder.png'
    },
    {
        name: 'cube2Img',
        type: 'texture',
        path: 'img/cube2.png'
    },
    {
        name: 'cube3Img',
        type: 'texture',
        path: 'img/cube3.png'
    },
    {
        name: 'reelEImg',
        type: 'texture',
        path: 'img/reelE.png'
    },
    {
        name: 'letters',
        type: 'texture',
        path: 'img/letters.png'
    },
    {
        name: 'spheres',
        type: 'texture',
        path: 'img/spheres.png'
    },
    {
        name: 'vImg',
        type: 'texture',
        path: 'img/v.png'
    },
    {
        name: 'sticksImg',
        type: 'texture',
        path: 'img/7.png'
    },
    // {
    //     name: 'cross1Img',
    //     type: 'texture',
    //     path: 'img/cross1.jpg'
    // },
    // {
    //     name: 'cross1Img',
    //     type: 'texture',
    //     path: 'img/cross1.jpg'
    // },
    // {
    //     name: 'cross1Img',
    //     type: 'texture',
    //     path: 'img/cross1.jpg'
    // },
    // {
    //     name: 'cross1Img',
    //     type: 'texture',
    //     path: 'img/cross1.jpg'
    // },


]