import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Floor
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time

        // Resource
        this.resource = this.resources.items.floorModel

        this.setTextures()
        this.setMaterial()
        this.setModel()
        
        
    }
    setTextures()
    {
        this.textures = {}

        this.textures.map = this.resources.items.floorTexture
        this.textures.map.encoding = THREE.sRGBEncoding
        this.textures.map.flipY = false
        
    //     this.textures.alpha = this.resources.items.floorAlpha
    //     this.textures.alpha.encoding = THREE.sRGBEncoding
    //     this.textures.alpha.flipY = false
    //   this.textures.alpha.premultiplyAlpha=true
    }

    setMaterial()
    {
        this.floorMaterial = new THREE.MeshBasicMaterial({
            map: this.textures.map,
            transparent:true,
            opacity:1,
            depthWrite:true,
            depthTest: true,
            // side: THREE.DoubleSide
        })
                
    }

    
    setModel()
    {   
        this.model = this.resource.scene
        this.model.position.set(-0.001,-0.001,0)
        this.model.scale.set(.0294, .0294, .0294)
        this.model.rotation.y=(Math.PI*2/360)*360
      
        
        
        this.scene.add(this.model)
        
        this.model.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {   
                // console.log(child)
                child.material = this.floorMaterial
                child.material.depthWrite=true
               
            }
        })
    }

    meshify()
    {
        this.model.traverse((child) =>
        {  
            if(child instanceof THREE.Mesh)
            {
            
            child.material.wireframe = true
            child.material.wireframeLinewidth = 10
            child.material.wireframeLinecap = 'round'
            child.material.wireframeLinejoin = 'round'
            }
        })

    }
    desmeshify()
    {
        this.model.traverse((child) =>
        {  
            if(child instanceof THREE.Mesh)
            {
            child.material.wireframe = false
            }
        })

    }
   

    update()
    {
        // this.model.rotation.y += this.time.delta/-20000
    }
    // update(scrollY)
    // {
    //     scrollY = window.scrollY
    //     const map_range = (value, low1, high1, low2, high2) => {
    //         return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
    //     }
    //     let playback =  map_range(scrollY, 100,2000,0,3.5) 
    //     let playbackClamped = Math.min(playback,3.4)

    //     if(scrollY<100){
    //         this.animation.mixer.setTime( 0 )
    //     }
    //     else {
      
    //     // console.log("playback: "+playbackClamped)
    //     this.animation.mixer.setTime( playbackClamped )
    //     }
    // }
}