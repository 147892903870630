import Experience from '../Experience.js'
import Environment from './Environment.js'
import Floor from './Floor.js'
import Box from './Box.js'
import Overlay from './Overlay.js'

export default class World
{
    constructor()
    {
        this.experience = new Experience()        
        this.scene = this.experience.scene
        this.overlay = new Overlay()
        this.resources = this.experience.resources

        // Wait for resources
        this.resources.on('ready', () =>
        {
            // Setup
            this.box = new Box()
            this.floor = new Floor()
            this.environment = new Environment()
        })
    }

    update()
    {
        // this.environment.update()
    }
}