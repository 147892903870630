import * as THREE from 'three'
import { gsap } from 'gsap'

import Debug from './Utils/Debug.js'
import Sizes from './Utils/Sizes.js'
import Time from './Utils/Time.js'
import Camera from './Camera.js'
import Renderer from './Renderer.js'
import World from './World/World.js'
import Resources from './Utils/Resources.js'

import sources from './sources.js'
import { Face3 } from 'three'

let instance = null
let canScroll = false
let vSwitch = true


export default class Experience
{
    constructor(_canvas,_loadingGroup,_loadingBar,_loadingPercent,progressContainer,progressBar,_title,_scrollSign,_endPage,_vicvLogo,_debugger,_wireButton,_pageOne,_pageTwo,_pageThree,_pageFour,_pageFive)
    {
        // Singleton
        if(instance)
        {
            return instance
        }
        instance = this
        
        // Global access
        window.experience = this

        // Options
        this.canvas = _canvas
        this.loadingGroup = _loadingGroup
        this.loadingBar = _loadingBar
        this.loadingPercent = _loadingPercent
        this.progressContainer = progressContainer
        this.progressBar = progressBar
        this.endPage = _endPage
        this.title = _title
        this.scrollSign = _scrollSign
        this.vicvLogo = _vicvLogo
        this.debugger = _debugger
        this.wireButton = _wireButton
        this.pageOne = _pageOne
        this.pageTwo = _pageTwo
        this.pageThree = _pageThree
        this.pageFour = _pageFour
        this.pageFive = _pageFive
        // Setup
        this.debug = new Debug()
        this.sizes = new Sizes()
        this.time = new Time()
        this.scene = new THREE.Scene()
        this.resources = new Resources(sources)
        this.camera = new Camera()
        this.renderer = new Renderer()
        this.world = new World()

        this.scene.background = new THREE.Color( '#ffcfde' )

        this.cam = this.camera.instance
        this.camOne = this.camera.instanceGroup
        this.camTwo = this.camera.instanceGroupTwo
        this.camOrbit = this.camera.instanceOrbitGroup

        // Resize event
        this.sizes.on('resize', () =>
        {
            this.resize()
        })
        // LOADED
        this.resources.on('oneMore', () =>
        {           
            this.loadingBar.style.transform = `scaleX(${this.resources.loaded/this.resources.toLoad})`
            this.loadingPercent.innerHTML = `${Math.round(this.resources.loaded/this.resources.toLoad*100)}%`
            window.scrollTo(0, 0)
        })

        this.resources.on('ready', () =>
        {   
           document.body.style.height = `${this.sizes.expHeight}px`
            window.scrollTo(0, 0)
            this.wireButton.style.display = 'block'
            gsap.to(this.loadingGroup, {opacity:0})
            gsap.timeline()
                .to(this.world.overlay.overlayMaterial.uniforms.uAlpha, { duration: 1.5, value: 0 })
                .to(this.title, {  opacity: 1 },'<')
                .to(this.scrollSign, {  opacity: 1 },'<')
                .from(this.camera.instanceGroupTwo.rotation,{y:0.6,duration:2,ease:'power4.out'},'<') 
                .from(this.cam.position,{z:3,y:0.4,duration:1.5,ease:'power3.out'},'<')
                .from(this.wireButton ,{xPercent:-650, opacity:0,duration:1,ease:'power2.out'},'<1')
                
            canScroll = true
        })

        // Scrolling
        this.scrollY = 0
        this.scrollable = this.sizes.expHeightScroll
        let mainPlayhead = 0

        if(window.srollY>0){
            window.scrollTo(0, 0)
            // this.camera.update()
        }

        this.sizes.on('scrolling', () =>
        {   
            
            // console.log(this.camOne.position.y,'-',this.camTwo.position.y)
            this.scrollY = window.scrollY
            let mainPlayhead = 0
            console.log(this.scrollY)

                              
           if(canScroll){

                if(this.scrollY < 1){
                    mainPlayhead = 0.0001
                }
                else
                {
                    mainPlayhead = this.scrollY/this.scrollable
                }
                
               

                // BOX TIMELINIE
                var boxTl = gsap.timeline()
                    .fromTo(this.progressBar,{x:'-100%'},{x:0, duration:19.8 , ease:'linear'}).pause()
                    .fromTo(this.world.box.animation.mixer,{'setTime':0},{'setTime':3.5, duration:6, ease: 'sine.inOut'},'<1.5').pause()
                    .fromTo(this.world.box.animation.mixer,{'setTime':0},{'setTime':3.5, duration:5, ease: 'power1.inOut'},'<12.5').pause()
                    .fromTo(this.world.box.onlyV.position,{y:-0.1},{y:1.8, duration:5, ease: 'power1.inOut'},'<').pause()
                    .fromTo(this.world.box.onlyV.scale,{x:0.01,y:0.01,z:0.01},{x:0.03,y:0.03,z:0.03, duration:5, ease: 'power2.inOut'},'<').pause()
                    .fromTo(this.world.box.onlyV.rotation,{x:6,y:2,z:8},{x:0,y:0,z:0, duration:5, ease: 'power1.inOut'},'<').pause()
                    .fromTo(this.progressBar,{x:0},{x:0, duration:0.2, ease:'linear'}).pause()
                boxTl.progress(mainPlayhead).pause()



                // CAM TIMELINIE
                let initialY = 0.33
                let initRot= -20
                let initialCamZ = 1.8
                let oneD= Math.PI*2/360

                var camTl = gsap.timeline()
                    
                    // To first page
                    .fromTo(this.camTwo.position, {x:0,y:0,z:0}, {x:-0.45,y:-0.05,z:0,duration:2,ease:'power1.inOut'})
                    .fromTo(this.camTwo.rotation, {x:0},{x:oneD*-12,duration:2,ease:'power1.inOut'},'<').pause()
                    .fromTo(this.camOne.rotation, {y:oneD*initRot},{y:oneD*40, duration:2, ease:'power1.inOut'}, '<').pause()
                    .to(this.camOne.position,{y:initialY,duration:0},'<')

                    // To center and up
                    .fromTo(this.camTwo.position, {x:-0.45,y:-0.05,z:0,}, {x:0,y:0,z:0,duration:2,ease:'power1.inOut'})
                    .fromTo(this.camTwo.rotation,{x:oneD*-12},{x:oneD*0,duration:4,ease:'power1.inOut'},'<').pause()
                    .fromTo(this.camOne.position,{y:initialY},{y:initialY+1.4, duration:6, ease: 'power1.inOut'},'<').pause()
                    .fromTo(this.cam.position,{z:1.8},{z:2.2, duration:3, ease: 'power1.inOut'},'<').pause()
                    .fromTo(this.camOne.rotation,{y:oneD*40},{y:oneD*380, duration:6, ease:'power1.in'}, '<').pause()
                    
                    .to(this.camOne.position,{y:0.5,duration:2,ease:'power1.inOut'}).pause()
                    .fromTo(this.camOne.rotation,{y:oneD*380},{y:oneD*670, duration:2.5, ease:'power1.out'}, '<').pause()
                    .to(this.cam.position,{z:3, duration:2, ease: 'power1.inOut'},'<').pause()
                    .fromTo(this.camTwo.rotation, {x:0},{x:oneD*-8,duration:2,ease:'power1.inOut'},'<').pause()
                    .fromTo(this.camTwo.position, {x:0}, {x:0.32,duration:1,ease:'power1.inOut'},'-=1')

                    .fromTo(this.camTwo.position, {x:0.32}, {x:-0.1,duration:2,ease:'power1.inOut'})
                    .to(this.camOne.position,{y:0.2, duration:2, ease: 'power1.inOut'},'<').pause()
                    .fromTo(this.camTwo.rotation,{x:oneD*-8},{x:oneD*1,duration:2,ease:'power1.inOut'},'<').pause()
                    .to(this.cam.position,{z:1.5, duration:2, ease: 'power1.inOut'},'<').pause()            
                    .fromTo(this.camOne.rotation,{y:oneD*670},{y:oneD*500, duration:2, ease:'power2.in'},'<').pause()
                              
                    
                    // Hidden Movement
                    .to(this.cam.position,{z:3, duration:2, ease: 'power1.inOut'}).pause() 
                    .to(this.camOne.rotation,{y:oneD*320, duration:2, ease:'power1.inOut'},'<').pause()
                    .to(this.camOne.position,{y:2, duration:1, ease:'power1.out'},'<').pause()
                    .to(this.camTwo.position,{z:5,duration:2,ease:'power4.inOut'},'<').pause()
                    .to(this.camTwo.rotation, {x:oneD*0,duration:1,ease:'power1.inOut'},'<').pause()

                    // Final Movements
                    .to(this.camOne.rotation,{y:oneD*720, duration:5.5, ease:'power1.inOut'}).pause()
                    .fromTo(this.camTwo.position,{z:5},{z:0,duration:4,ease:'power1.inOut'},'<').pause()
                    .fromTo(this.camOne.position,{y:2.5},{y:1, duration:1.5, ease:'power1.inOut'},'<').pause()
                    .to(this.camOne.position,{y:initialY+1.75, duration:4, ease:'power1.inOut'},'<1.5').pause()
                    
                    // Final Gfx Adjustment
                    .fromTo(this.cam.position,{x:0},{x:-0.45, duration:1.5, ease: 'power1.inOut'},"-=1.5").pause()
                   
                    

                camTl.progress(mainPlayhead).pause()

                let number = {value:0}
                // GFX TIMELINIE
                var gfxTl = gsap.timeline()

                    .fromTo(this.title,{opacity:1}, {  opacity: 0, duration: 0.5 }).pause()
                    .fromTo(this.scrollSign,{opacity:1}, {  opacity: 0 },'<').pause()
                    .fromTo(this.progressContainer,{opacity:0},{opacity: 1,duration:0.2 },'-=0.2').pause()

                    // GFX - 01 - IN - OUT
                    .fromTo(this.pageOne,{x:'-100%'},{x:'0%', duration:1, ease: 'power2.inOut'}).pause()
                    .to(this.pageOne,{x:'-100%', duration:1, ease: 'power2.inOut'}).pause()

                    .fromTo(this.progressContainer,{x:0},{x:0, duration:7}).pause()

                    // GFX - 02 - IN - OUT
                    .fromTo(this.pageTwo,{x:'100%'},{x:'0%', duration:1, ease: 'power2.inOut'}).pause()
                    .to(this.pageTwo,{x:'100%', duration:1, ease: 'power2.inOut'}).pause()

                    

                    // GFX - 03 - IN - OUT
                    .fromTo(this.pageThree,{y:'100%'},{y:'0%', duration:1, ease: 'power2.inOut'}).pause()
                    .fromTo(this.pageFour,{y:'100%'},{y:'0%', duration:1, ease: 'power2.inOut'}).pause()
                    .fromTo(this.pageFive,{y:'100%'},{y:'0%', duration:1, ease: 'power2.inOut'}).pause()
                    .to(this.pageFive,{y:'-100%', duration:1, ease: 'power2.inOut'}).pause()
                    .to(this.pageFour,{y:'-100%', duration:0.5, ease: 'power2.in'},'<').pause()
                    .to(this.pageThree,{y:'-100%', duration:0.5, ease: 'power2.in'},'<').pause()


                    .fromTo(this.progressContainer,{x:0},{x:0, duration:3.5 }).pause()
                    

                    .fromTo(this.endPage,{x:'-100%'},{x:'0%', duration:1, ease: 'power2.inOut'}).pause()
                    .to(this.progressContainer,{  opacity: 0,duration:0.5 },'<0.5').pause()
        
                    
                gfxTl.progress(mainPlayhead).pause()
               
                    
                if(this.scrollY < 1){
                    this.camOne.position.y=initialY
                    this.camOne.rotation.y=oneD*initRot
                    this.cam.rotation.x=oneD*-6
                }
   
            }
            if (vSwitch == true){
                console.log(vSwitch)
                if(this.scrollY > this.sizes.expHeightScroll*0.65){
                    this.world.box.switchV()
                    vSwitch = false
                }
            }
            else if(vSwitch == false){
                if(this.scrollY < this.sizes.expHeightScroll*0.65 ){
                    this.world.box.switchVBack()
                    vSwitch = true
                }
            }

            // DEBUG
            if(window.location.hash === '#debug'){
                this.debugger.style.display = 'block'
                if(camTl.duration() != gfxTl.duration()){ 
                alert('Timelines arent\'t iqual')
                }
                this.debugger.innerHTML = `Cam:${camTl.duration()} Box:${boxTl.duration()} Gfx:${gfxTl.duration()}<br>${Math.round((20*mainPlayhead)*100)/100}`
            }
        })
        // MOUSE MOVING
        this.cursor = {}
        this.cursor.x = 0
        this.cursor.y = 0

        window.addEventListener('mousemove', (event) =>
        {
            this.cursor.x = event.clientX / this.sizes.width  - 0.35
            this.cursor.y = event.clientY / this.sizes.height - 0.5
            this.camera.paralax(this.cursor.x,this.cursor.y)
        })

        // BUTTON
        let wiresState = false
       

        this.wireButton.addEventListener('click', ()=>{
            if(wiresState == false){
                this.world.box.meshify()
              
                gsap.timeline()
                    .to(this.world.floor.model.children[0].material,{opacity:0,duration:0.2})
                    .to(this.scene.background,{r:0.1,g:0.1,b:0.1,duration:0.8},'<0.1')
                    
                this.wireButton.innerHTML = 'Hide Wireframe'
                wiresState = true
            }else{
                this.world.box.desmeshify()
                
                gsap.timeline()
                    .to(this.scene.background,{r:1,g:0.8117647,b:0.870588,duration:0.6})
                    .to(this.world.floor.model.children[0].material,{opacity:1,duration:1,ease:'power3.inOut'},'-=0.6')
                
                this.wireButton.innerHTML = 'Show Wireframe'
                wiresState = false
            }
        })      
        // Time tick event
        this.time.on('tick', () =>
        {
            this.update()
            // this.camera.paralax(cursor.x,cursor.y)
        })
    }

    resize()
    {
        this.camera.resize()
        this.renderer.resize()
    }

    update()
    {
        // this.camera.update()
        if(canScroll){
            this.world.environment.update()
        }
        this.renderer.update() 
        this.camera.paralax(this.cursor.x,this.cursor.y)
    }

    
}