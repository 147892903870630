import * as THREE from 'three'
import Experience from '../Experience.js'
import { gsap } from 'gsap'

export default class Box
{
    constructor()
    {
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug


        // Resource
        this.boxResource = this.resources.items.boxModel
        this.onlyVResource = this.resources.items.onlyV

        this.setTextures()
        this.setMaterial()
        this.setModel()
        this.setAnimation()
    }

    setTextures()
    {
        
        this.textures = {}
       
        this.textures.box = this.resources.items.boxImg
        this.textures.cross1 = this.resources.items.cross1Img
        this.textures.cross2 = this.resources.items.cross2Img
        this.textures.cube1 = this.resources.items.cube1Img
        this.textures.tube = this.resources.items.tubeImg
        this.textures.ease = this.resources.items.easeImg
        this.textures.cont = this.resources.items.contImg
        this.textures.cyl = this.resources.items.cylImg
        this.textures.cube2 = this.resources.items.cube2Img
        this.textures.cube3 = this.resources.items.cube3Img
        this.textures.letters = this.resources.items.letters
        this.textures.spheres = this.resources.items.spheres
        this.textures.v = this.resources.items.vImg
        this.textures.sticks = this.resources.items.sticksImg

        this.textures.toon = this.resources.items.toonImg

        this.textures.toon.magFilter = THREE.NearestFilter  
        this.textures.letters.magFilter = THREE.NearestFilter  

        for (const textureTemp of Object.entries(this.textures)) {
            textureTemp[1].encoding = THREE.sRGBEncoding
            textureTemp[1].flipY = false
            
          }
        
    }

    setMaterial()
    {

        this.materials= {}
        
        this.materials.box = new THREE.MeshBasicMaterial({  map: this.textures.box,depthWrite: true  })
        this.materials.bottomBox = new THREE.MeshBasicMaterial({  color: 0xa5927b  })

        this.materials.cross1 = new THREE.MeshToonMaterial({ map: this.textures.cross1, gradientMap:this.textures.toon })
        this.materials.cross2 = new THREE.MeshToonMaterial({ map: this.textures.cross2, gradientMap:this.textures.toon })
        this.materials.cube1 = new THREE.MeshToonMaterial({ map: this.textures.cube1, gradientMap:this.textures.toon })
        this.materials.tube = new THREE.MeshToonMaterial({ map: this.textures.tube, gradientMap:this.textures.toon })
        this.materials.ease = new THREE.MeshToonMaterial({ map: this.textures.ease, gradientMap:this.textures.toon })
        this.materials.cont = new THREE.MeshToonMaterial({ map: this.textures.cont, gradientMap:this.textures.toon })
        this.materials.cyl = new THREE.MeshToonMaterial({ map: this.textures.cyl, gradientMap:this.textures.toon })
        this.materials.cube2 = new THREE.MeshToonMaterial({ map: this.textures.cube2, gradientMap:this.textures.toon })
        this.materials.cube3 = new THREE.MeshToonMaterial({ map: this.textures.cube3, gradientMap:this.textures.toon })
        this.materials.v = new THREE.MeshToonMaterial({ map: this.textures.v, gradientMap:this.textures.toon })
        
        this.materials.letters = new THREE.MeshToonMaterial({ map: this.textures.letters })
        this.materials.spheres = new THREE.MeshMatcapMaterial({ matcap: this.textures.spheres })
        this.materials.sticks = new THREE.MeshMatcapMaterial({ map: this.textures.sticks })

        
        // this.materials.cross1 = new THREE.MeshBasicMaterial({ map: this.textures.cross1 })
        // this.materials.cross2 = new THREE.MeshBasicMaterial({ map: this.textures.cross2 })
        // this.materials.cube1 = new THREE.MeshBasicMaterial({ map: this.textures.cube1 })
        // this.materials.tube = new THREE.MeshBasicMaterial({ map: this.textures.tube })
        // this.materials.ease = new THREE.MeshBasicMaterial({ map: this.textures.ease })
        // this.materials.cont = new THREE.MeshBasicMaterial({ map: this.textures.cont })
        // this.materials.cyl = new THREE.MeshBasicMaterial({ map: this.textures.cyl })
        // this.materials.cube2 = new THREE.MeshBasicMaterial({ map: this.textures.cube2 })
        // this.materials.cube3 = new THREE.MeshBasicMaterial({ map: this.textures.cube3 })
        // this.materials.letters = new THREE.MeshMatcapMaterial({ matcap: this.textures.letters })
        // this.materials.spheres = new THREE.MeshMatcapMaterial({ matcap: this.textures.spheres })
        // this.materials.v = new THREE.MeshBasicMaterial({ map: this.textures.v })
        // this.materials.sticks = new THREE.MeshMatcapMaterial({ map: this.textures.sticks })
        
        // this.materials.letters.gradientMap.magFilter = THREE.NearestFilter

        // Apply skinning and morphtarget to all materials
        for (const materialTemp of Object.entries(this.materials)) {
            materialTemp[1].skinning = true
            materialTemp[1].morphTargets = true
            materialTemp[1].envMapIntensity = 1
            materialTemp[1].reflectivity=.5
            // materialTemp[1].wireframe=true
          }
    }

    setModel()
    {   
        this.box = this.boxResource.scene
        this.box.scale.set(.03, .03, .03)
        this.scene.add(this.box)

        this.onlyV = this.onlyVResource.scene
        this.onlyV.scale.set(.03, .03, .03)
        this.onlyV.position.set(0,1.8,0)
        this.onlyV.rotation.y = Math.PI*2/360*-30
        this.scene.add(this.onlyV)

        this.box.traverse((child) =>
        {   
            if(child instanceof THREE.Mesh && child.name === 'box_3')
            {
                child.material = this.materials.box
            }
            if(child instanceof THREE.Mesh && child.name === 'cross1')
            {
                child.material = this.materials.cross1
            }
            if(child instanceof THREE.Mesh && child.name === 'cross2')
            {
                child.material = this.materials.cross2
            }
            if(child instanceof THREE.Mesh && child.name === 'cube1')
            {
                child.material = this.materials.cube1
            }
            if(child instanceof THREE.Mesh && child.name === 'tube')
            {
                child.material = this.materials.tube
            }
            if(child instanceof THREE.Mesh && (child.name === 'ease1' || child.name === 'ease2'))
            {
                child.material = this.materials.ease
            }
            if(child instanceof THREE.Mesh && (child.name === 'cont2' || child.name === 'cont1'))
            {
                child.material = this.materials.cont
            }
            if(child instanceof THREE.Mesh && child.name === 'cyl1')
            {
                child.material = this.materials.cyl
            }
            if(child instanceof THREE.Mesh && child.name === 'cube2')
            {
                child.material = this.materials.cube2
            }
            if(child instanceof THREE.Mesh && child.name === 'cube3')
            {
                child.material = this.materials.cube3
            }
            if(child instanceof THREE.Mesh && child.name === 'cube4')
            {
                child.material = this.materials.cube1
            }
            if(child instanceof THREE.Mesh && child.name === 'v')
            {
                child.material = this.materials.v
            }
            if(child instanceof THREE.Mesh && child.name === 'bottomBox')
            {
                child.material = this.materials.bottomBox
            }
            if(child instanceof THREE.Mesh && (child.name === 'l'|| child.name === 'e1'|| child.name === 'e2'|| child.name === 'r' ))
            {
                child.material = this.materials.letters
            }
            if(child instanceof THREE.Mesh && (child.name === 'sphere1'|| child.name === 'sphere2'|| child.name === 'sphere3'))
            {
                child.material = this.materials.spheres 
            }
            if(child instanceof THREE.Mesh && (child.name === 'stick1'|| child.name === 'stick2'|| child.name === 'stick3'))
            {
                child.material = this.materials.sticks
            }
            // if(child instanceof THREE.Mesh)
            // {
            //    console.log(child)
            // }   
        })
        this.onlyV.traverse((child) =>
        {   
           
            if(child instanceof THREE.Mesh)
            {
                child.material = this.materials.v     
                child.visible = false       
            }
            
        })
    }

    setAnimation()
    {
        this.animation = {}
        
        // Mixer
        this.animation.mixer = new THREE.AnimationMixer(this.box)
        
        // Actions
        this.animation.actions = {}
        
        this.animation.actions.idle = this.animation.mixer.clipAction(this.boxResource.animations[0])
     
        
        this.animation.actions.current = this.animation.actions.idle
        this.animation.actions.current.play()

        this.animation.mixer.setTime( 0 )

    

        
    }

    update()
    {
        this.box.rotation.y += this.time.delta/-20000
    }
    updateScroll(scrollY)
    {

    }

    meshify()
    {
        this.box.traverse((child) =>
        {  
            if(child instanceof THREE.Mesh)
            {
                
                child.material.wireframe = true
                child.material.wireframeLinewidth = 10
                child.material.wireframeLinecap = 'round'
                child.material.wireframeLinejoin = 'round'
                 
            }
        })

    }
    desmeshify()
    {
        this.box.traverse((child) =>
        {  
            if(child instanceof THREE.Mesh)
            {
            child.material.wireframe = false
            }
        })

    }
    switchV()
    {
        this.onlyV.traverse((child) =>
        {   
           
            if(child instanceof THREE.Mesh)
            {
                child.visible = true            
            }
            
        })
        
        this.box.traverse((child) =>
        {
            if(child instanceof THREE.Mesh && child.name === 'v')
            {
                child.visible = false
            }
        })
    }
    switchVBack()
    {
        this.onlyV.traverse((child) =>
        {   
           
            if(child instanceof THREE.Mesh)
            {
                child.visible = false            
            }
            
        })
        
        this.box.traverse((child) =>
        {
            if(child instanceof THREE.Mesh && child.name === 'v')
            {
                child.visible = true
            }
        })
    }
}

        