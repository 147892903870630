import './style.css'

import Experience from './Experience/Experience.js'

window.scrollTo(0, 0);

// window.scrollTo({ top: 0, behavior: 'smooth' })

const failOverlay = document.querySelector('.failContainer')  

if(window.location.hash === '#emb'){
    document.querySelector('.vicvLogo').style.display = 'none'
}

if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    failOverlay.style.display = 'flex';
}else{
    const experience = new Experience(
        document.querySelector('canvas.webgl'),
        document.querySelector('.loadingGroup'),
        document.querySelector('.loading-bar'),
        document.querySelector('.loadingPercent'),
        document.querySelector('.progressMasterCont'),
        document.querySelector('.progressBar'),
        document.querySelector('.title'),
        document.querySelector('.scrollSign'),
        document.querySelector('.endPage'),
        document.querySelector('.vicvLogo'),
        document.querySelector('.debugger'),
        document.querySelector('.wireButton'),
        document.querySelector('.pageOne'),
        document.querySelector('.pageTwo'),
        document.querySelector('.pageThree'),
        document.querySelector('.pageFour'),
        document.querySelector('.pageFive')
        )
}

