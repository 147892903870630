import * as THREE from 'three'
import Experience from './Experience.js'
import { gsap } from 'gsap'

let initialY = .33
let initRot= -20
let initialCamZ = 1.8

let oneD= Math.PI*2/360

export default class Camera
{
    constructor()
    {
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas
        this.debug = this.experience.debug

         // Debug
        //  if(this.debug.active)
        //  {
        //      this.debugFolder = this.debug.ui.addFolder('Camera')
        //  }
        
        

        this.setInstance()
        this.setControls()
    }

    setInstance()
    {
        this.instance = new THREE.PerspectiveCamera(20, this.sizes.width / this.sizes.height, 0.2, 10)
        this.instanceGroup = new THREE.Group()
        this.instanceGroupTwo = new THREE.Group()
        this.instanceOrbitGroup = new THREE.Group()

        this.scene.add(this.instanceGroup)
        this.instanceGroup.add(this.instanceOrbitGroup)
        this.instanceOrbitGroup.add(this.instanceGroupTwo)
        this.instanceGroupTwo.add(this.instance)

        this.instance.position.set(0, 0, initialCamZ)
        this.instanceGroup.position.set(0, initialY, 0)
        
        this.instanceGroup.rotation.y=oneD*initRot
        this.instance.rotation.x=oneD*-6
        
        // Debug
        // if(this.debug.active)
        // {
        
        //     this.debugFolder
        //         .add(this.instance.position, 'x')
        //         .name('CamX')
        //         .min(-2)
        //         .max(2)
        //         .step(0.001)
            
        //     this.debugFolder
        //         .add(this.instance.position, 'y')
        //         .name('CamY')
        //         .min(0)
        //         .max(2)
        //         .step(0.001)
            
        //     this.debugFolder
        //         .add(this.instance.position, 'z')
        //         .name('CamZ')
        //         .min(- 4)
        //         .max(4)
        //         .step(0.001)
        // }
    }

    setControls()
    {
        // this.controls = new OrbitControls(this.instance, this.canvas)
        // this.controls.enableDamping = true
        // this.controls.target.set(0,0.2,0)   
    }

    resize()
    {
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
    }

    

    update(scrollY)
    {
            scrollY = window.scrollY
        
            if(scrollY<1)
            {
                this.instanceGroup.position.y=initialY
                this.instanceGroup.rotation.y=oneD*initRot
    
                this.instance.rotation.x=oneD*-6
            }
            else
            {                
              
               
              
                
                var camTl = gsap.timeline()
                            .fromTo(this.instanceGroup.position, {y:initialY},{y:initialY+0.1, duration:10, ease: 'power1.inOut'}).pause()
                            .fromTo(this.instance.rotation,{x:oneD*-6},{x:0, duration:10, ease:'sine.inOut'}, '<')
                            .fromTo(this.instanceGroup.rotation,{y:oneD*initRot},{y:oneD*(150+180), duration:10, ease:'power1.inOut'}, '<').pause()
                            .fromTo(this.instance.position,{z:initialCamZ},{z:initialCamZ + 1.2, duration:2, ease: 'sine.inOut'}, '<').pause()
                            .fromTo(this.instance.position,{z:initialCamZ+1.2},{z:initialCamZ - 0.2, duration:4, ease: 'power2.inOut'}, '<6').pause()
                            .fromTo(this.instanceGroup.position, {y:initialY+0.1},{y:initialY+1.47, duration:2, ease: 'sine.inOut'},'-=0.2 ').pause()
                camTl.progress(scrollY/this.sizes.expHeightScroll)

     
            }

            


        //  this.controls.update()
    }
    paralax(cursorX,curxorY){

    this.paralaxX = cursorX 
    this.paralaxY = -curxorY

    this.instanceOrbitGroup.position.x += ((this.paralaxX * 0.06 ) - this.instanceOrbitGroup.position.x) * 0.06
    this.instanceOrbitGroup.position.y += ((this.paralaxY* 0.06 ) - this.instanceOrbitGroup.position.y) * 0.06

    this.instanceOrbitGroup.rotation.x += ((this.paralaxY * 0.15 ) - this.instanceOrbitGroup.rotation.x) * 0.06
    this.instanceOrbitGroup.rotation.y += ((this.paralaxX * 0.15 ) - this.instanceOrbitGroup.rotation.y) * 0.06
    
  
    }
    
}